import React from "react"

export default props => (
  <div className={props.className}>
    <h3 className="hero-label">
      Sign up
    </h3>
    <p>
      Diagram is written, run and developed by me, <a href="https://blakehunsicker.com/">Blake Hunsicker</a>. Diagram is designed to help you explore the news and catch up on what you want to know.
    </p>
    <p>
      I have a lot of new features in the works. For occasional updates, consider sharing your email below.
    </p>
    <form
      name={props.location}
      method="post"
      action="/form-success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={props.location} />
      <div className="address-form">
        <label htmlFor="email">
          <h4>
            Your email address
          </h4>
        </label>
        <input type="email" name="email" id="email" />
      </div>
      <div>
        <input type="submit" value="Submit" />
      </div>
    </form>
    <h5 className="copyright">Copyright 2019 Diagram</h5>
  </div>
)