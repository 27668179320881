import React from "react"
import { Link, graphql } from "gatsby"
import Footer from "../components/footer"
import SEO from "../components/seo"
import ConditionalLayout from "../components/conditional-layout"

export default () => {

  return (
    <ConditionalLayout>
      <SEO title="About" />

      <div className="head-title">
        <h2 className="hero-label">
          About Diagram
        </h2>
      </div>

      <div className="head-body">
        <p>
          Diagram is designed to help you catch up on the news and explore what you want to know.
        </p>

        <ul>
          <li>
            Diagram publishes concise and easy-to-read posts, and creates live section pages for news events, big companies and long term trends.
          </li>
          <li>
            Users can find related people biographies on every page, along with background information and longreads when they want to go deep.
          </li>
          <li>
            I don’t let posts die on the vine-- they get updated. The same topics and ideas come up over and over again in the news, so I keep them fresh and reusable.
          </li>
        </ul>

        <Footer
          className="about-footer"
          location="about"
        />
        
      </div>

      </ConditionalLayout>
  )
}